// extracted by mini-css-extract-plugin
export var bg1 = "overlay-module--bg1--77831";
export var bg2 = "overlay-module--bg2--2d4d1";
export var bg3 = "overlay-module--bg3--65740";
export var bg4 = "overlay-module--bg4--dea2b";
export var color_overlay = "overlay-module--color_overlay--421a1";
export var container = "overlay-module--container--08cd9";
export var frame = "overlay-module--frame--a3252";
export var image = "overlay-module--image--d05ef";
export var main_overlay = "overlay-module--main_overlay--81339";
export var overlay = "overlay-module--overlay--f280f";
export var secondaryFiller = "overlay-module--secondaryFiller--7285a";
export var secondary_overlay = "overlay-module--secondary_overlay--31289";